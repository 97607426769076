.header-img {
    width: 100%;
    height: 100%;
    background-image: url("../public/ff_bg.jpg");
  }

.feedback-img {
    background-image: url("../public/world-map.png");
    object-fit: cover;
    height: 100%;
    background-repeat: no-repeat;
}

.footer-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-image: url("../public/ff_bg.jpg");
    background-color: black;
  }